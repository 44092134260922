import React from "react"
import styled from "styled-components"

const BackgroundVideo = ({ src, children, ...props }) => {
  return (
    <VideoContainer>
      <Video {...props}>
        <source src={src} type="video/mp4" />
        <source src={src} type="video/ogg" />
        Your browser does not support the video tag.
      </Video>
      {children}
    </VideoContainer>
  )
}

export default BackgroundVideo

const VideoContainer = styled.div`
  position: relative;
  padding: 15px;
  overflow: hidden;
`
const Video = styled.video.attrs({
  loop: true,
  muted: true,
  autoPlay: true,
  playsInline: true,
  preload: "none",
})`
  width: 100%;
  background-color: rgba(0, 0, 0, 0.05);
  transform: scale(1.2);
`
