import React from "react"
import styled from "styled-components"
import SEO from "../components/seo"
import Layout from "../components/layout"
import { useTranslation } from "react-i18next"
import video from "../assets/clouds.mp4"
import { graphql } from "gatsby"
import _ from "lodash"
import Image from "gatsby-image"
import BackgroundVideo from "../components/BackgroundVideo"

const IndexPage = ({ data }) => {
  const { t } = useTranslation()
  const partners = _.chain(data)
    .get("allContentfulPartners.edges", [])
    .map(({ node }) => node)
    .sortBy(["order"])
    .value()
  return (
    <Layout>
      <SEO title="Tell your story | Clapesque" />
      <BackgroundVideo src={video} />
      <Tagline>
        <div>{t("pages.landing.title")}</div>
        <div style={{ height: 10 }} />
        <div style={{ fontWeight: "bold" }}>{t("pages.landing.subtitle")}</div>
        <div style={{ height: 50 }} />
      </Tagline>
      <div
        style={{
          fontWeight: "bold",
          textTransform: "uppercase",
          textAlign: "center",
          marginBottom: 30,
        }}
      >
        {t(`pages.landing.partners`)}
      </div>
      <PartnersList>
        {(partners || []).map(({ id, name, logo, url }, index) => (
          <PartnerItem
            key={`partner.${index}`}
            href={url}
            target="_blank"
            rel="noopener noreferrer"
          >
            {logo?.sizes ? (
              <Image alt={name} sizes={logo?.sizes} />
            ) : (
              <div>{name}</div>
            )}
          </PartnerItem>
        ))}
      </PartnersList>
    </Layout>
  )
}

export default IndexPage

const Tagline = styled.div`
  padding: 50px 0;
  text-align: center;
`
const PartnersList = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0 -15px;
  @media (max-width: 1100px) {
    display: block;
  }
`
const PartnerItem = styled.a`
  margin: 0 15px;
  transition: filter 300ms ease;
  filter: grayscale(1);
  width: 93px;
  cursor: pointer;
  &:hover {
    filter: none;
  }
  @media (max-width: 1100px) {
    display: block;
    margin: 30px auto;
  }
`

export const query = graphql`
  query PartnersQuery {
    allContentfulPartners {
      edges {
        node {
          id
          name
          url
          order
          logo {
            sizes(maxWidth: 800) {
              ...GatsbyContentfulSizes
            }
          }
        }
      }
    }
  }
`
